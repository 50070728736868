@font-face {
    font-family: "Medium";
    src: local("RedHatDisplay-Medium"), url("../../../fonts/RedHatDisplay-Medium.ttf") format("truetype");
}

.nightMenu__Navbar {
    border: 1px solid #ffffff;
    border-radius: 10px;
    margin-left: 50px;
    margin-right: 50px;
    list-style: none;
    background-color: transparent;
    padding: 10px 0 10px 0;
    text-align: center;
}

.nightMenu__Link {
    font-family: "Medium";
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.115em;
    color: #ffffff;
    display: inline;
    margin: 50px;
    cursor: pointer;
}