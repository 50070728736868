.nightFooter__footerContainer {
    margin: 0 !important;
    background-color: #000000;
    color: #ffffff;
    padding-bottom: 20px;
}

.nightFooter__mainTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 47px;
}

.nightFooter__socialLinks {
    margin-top: 35px;
}

.nightFooter__whiteLink {
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 33px;
    text-decoration: none;
    display: block;
}

.nightFooter__whiteLink:hover {
    color: #7B7B7B;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 33px;
    text-decoration: none;
    display: block;
}

.nightFooter__whiteLink:active {
    color: #7B7B7B;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 33px;
    text-decoration: none;
    display: block;
}

.nightFooter__logoContainer {
    text-align: right;
}

.nightFooter__logoVolt {
    max-width: 100px;
}

.nightFooter__copyContainer {
    margin-top: 50px;
}

.nightFooter__copyText {
    font-size: 19px;
}

.nightFooter__legalContainer {
    text-align: right;
    margin-top: 50px;
}

.nightFooter__legalLink {
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 33px;
    text-decoration: none;
}

.nightFooter__legalLink:hover {
    color: #7B7B7B;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 33px;
    text-decoration: none;
}

.nightFooter__legalLink:active {
    color: #7B7B7B;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 33px;
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    .nightFooter__legalContainer {
        text-align: left;
        margin-top: 0;
    }
    .nightFooter__logoContainer {
        text-align: left;
        margin-top: 30px;
    }
}