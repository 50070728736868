@font-face {
  font-family: "Black";
  src: local("RedHatDisplay-Black"), url("./components/fonts/RedHatDisplay-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";
  src: local("RedHatDisplay-Bold"), url("./components/fonts/RedHatDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "BoldItalic";
  src: local("RedHatDisplay-BoldItalic"), url("./components/fonts/RedHatDisplay-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "ExtraBold";
  src: local("RedHatDisplay-ExtraBold"), url("./components/fonts/RedHatDisplay-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  src: local("RedHatDisplay-Medium"), url("./components/fonts/RedHatDisplay-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SemiBold";
  src: local("RedHatDisplay-SemiBold"), url("./components/fonts/RedHatDisplay-SemiBold.ttf") format("truetype");
}

.App {
  background-color: transparent;
}

.degenButton {
  color: #ffffff !important;
  border-color: #ffffff !important;
  background-color: transparent !important;
}

.degenButton:hover {
  color: #000000 !important;
  border-color: #ffffff !important;
  background-color: #ffffff !important;
}

.degenButton:active {
  color: #000000 !important;
  border-color: #ffffff !important;
  background-color: #ffffff !important;
}

.degenButtonLight {
  color: #000000 !important;
  border-color: #000000 !important;
  background-color: transparent !important;
}

.degenButtonLight:hover {
  color: #ffffff !important;
  border-color: #000000 !important;
  background-color: #000000 !important;
}

.degenButtonLight:active {
  color: #ffffff !important;
  border-color: #000000 !important;
  background-color: #000000 !important;
}

/* Night Landing */
.nightLanding__App {
  font-family: "Medium";
  color: #ffffff;
  background-color: black;
  background-image: url('/src/components/img/blackWallpaper.svg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.nightLanding__logoContainer {
  margin-bottom: 50px;
}

.nightLanding__logoVoltText {
  margin: 0 auto;
  width: 250px;
}

.nightLanding__swatchContainer {
  width: 11%;
  position: absolute;
  left: 0;
  top: 50%;
}

.nightLanding__colourSwatch {
  transform: rotate(-90deg);
  top: 110px;
  margin: 0 auto;
  width: 100%;
}

.nightLanding__disclaimerContainer {
  background-color: #000000;
  border: 1px solid #D6FF01;
  border-radius: 23px;
  margin: 30px;
  padding: 20px;
  width: 75%;
}

/* Night Home */
.nightHome__App {
  font-family: "Medium";
  color: #ffffff;
  background-color: black;
  background-image: url('/src/components/img/blackWallpaper.svg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 100vh;
}

.nightHome__Container {
  border-top: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  border-bottom: 0;
  border-radius: 20px;
  margin: 30px 30px 0 30px;
}

.nightHome__switchContainer {
  text-align: right;
  margin-right: 20px;
}

.nightHome__logoContainer {
  margin: 0 auto;
}

.nightHome__logoVoltText {
  margin: 0 auto;
  width: 650px;
}

.nightHome__videoContainer {
  text-align: center;
  margin-top: 20px;
  background-image: url('../src/components/img/bottleBg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.nightHome__video {
  width: 100%;
  overflow: hidden;
}

.nightHome__heroContainer {
  margin-left: 25px;
}

.nightHome__heroText {
  font-style: normal;
  font-weight: 700;
  font-size: 73px;
  line-height: 70px;
  color: #ffffff;
}

.nightHome_valuesContainer {
  margin-left: 25px;
  max-width: 70%;
}

.nightHome_valuesTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
}

.nightHome_valuesText {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 26px;
  color: #B0B0B0;
  margin-top: 30px;
}

.nightHome_teamContainer {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 60px;
}

.nightHome_teamContainer > h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 63px;
  line-height: 70px;
  color: #E5E2E5;
}

.nightHome_memberContainer {
  text-align: center;
  margin-top: 13px;
}

.nightHome__memberPhoto {
  width: 163px;
  height: 163px;
  overflow: hidden;
  border-radius: 50%;
  border: 3px solid #ff346e;
}

.nightHome__memberName {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 0px;
  color: #FFFFFF;
  margin-top: 30px;
}

.nightHome__memberRole {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 0px;
  color: #FFFFFF;
}

.nightHome__memberSocialContainer {
  text-align: center;
}

.nightHome__memberSocialIcon {
  width: 17px;
  margin-left: 5px;
  margin-right: 5px;
}

.nightHome_avatarBg {
  background-image: url('../src/components/img/avatarBg.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 1000px;
  background-position-x: center;
  width: 100%;
}

.nightHome_degenerationContainer {
  margin-top: 70%;
  text-align: center;
}

.nightHome__degenerationLogo {
  max-width: 65%;
}

.nightHome__degenerationTwitter {
  width: 50px;
  margin-top: 30px;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  .nightHome__heroText {
    font-style: normal;
    font-weight: 700;
    font-size: 43px;
    line-height: 40px;
    color: #ffffff;
  }
}
@media (max-width: 400px) {
  .nightHome__heroText {
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 30px;
    color: #ffffff;
  }
}