.nightForm__formContainer {
    background-color: #000000;
    border: 1px solid #ffffff;
    border-radius: 23px;
    margin: 30px;
    padding: 20px;
    width: 75%;
    margin-left: auto !important;
    margin-right: auto !important;
}

.textInput {
    background: #FFFFFF;
    border-radius: 15px !important;
}